.navMain {
  display: flex;
  padding: 2% 0;
  align-items: center;
  justify-content: center;
}

.navMainApp {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

/* search bar and results */

.searchMain {
  width: 375px;
  margin-left: 500px;
}

.searchMainProfiles {
  width: fit-content;
}

.searchBar {
  width: 345px;
  height: 44px;
  border-radius: 7px;
  padding-left: 40px;
  background-color: #fffff2;
  font-size: 16px;
  border-width: 0;
}

.searchIcon {
  position: absolute;
  margin-top: 12px;
  margin-left: 10px;
}

.searchIconProfile {
  position: absolute;
  margin-top: 12px;
  margin-left: 10px;
}

.searchCancel {
  display: none;
}

.searchResultDivDesktop {
  color: #fffff2;
  background-color: #2d2927;
  padding: 20px 15px;
  border-radius: 5px;
  position: absolute;
  z-index: 10;
  width: 345px;
  overflow-y: scroll;
  max-height: 350px;
}

.searchResultDivDesktop h3 {
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: 350;
}

.traitResultDivDesktop {
  display: flex;
  overflow-x: scroll;
  padding-bottom: 4px;
}

.traitResultDivDesktop::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 3px;
  height: 6px;
}

.traitResultDivDesktop::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #2d2927;
}

.searchResultDiv {
  display: none;
  color: #fffff2;
  background-color: #2d2927;
  padding: 20px 15px;
  border-radius: 5px;
  position: absolute;
  z-index: 10;
  width: 345px;
  overflow-y: scroll;
  max-height: 350px;
}

.searchResultDiv h3 {
  font-size: 16px;
  margin-bottom: 15px;
}

.personDiv {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 0 !important;
}

.imageContainer {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}

.smallImage {
  border-radius: 50%;
}

.bioAndUsername {
  margin-left: 10px;
}

.bioAndUsername h5 {
  font-size: 16px;
  font-weight: 400;
}

.bioAndUsername p {
  font-size: 14px;
  color: grey;
}

.mobileSearchMain {
  width: 0;
}

.mobileSearchBar {
  display: none;
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .searchMain {
    margin-left: 250px;
  }
}

@media only screen and (max-width: 768px) {
  .navMain {
    padding: 4% 14% 4% 4%;
    align-items: normal;
  }

  .navMain a {
    width: 100px;
    margin-left: 0;
  }

  .searchMain {
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-left: 0;
  }
  .searchBar {
    display: none;
  }

  .searchIcon {
    position: relative;
    background-color: #fffff2;
    border-radius: 50%;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 44px;
    margin-top: 0;
  }

  .searchIconProfile {
    display: none;
  }

  .searchResultDivDesktop {
    display: none;
  }
}
