.main {
  background-color: #181716;
}

.mainDiv {
  display: flex;
  flex-direction: column;
  /* height: 60vh; */
  /* color: #fffff2; */
  align-items: center;
  font-family: Circularstd-Book;
}

.mainDiv h1 {
  font-size: 250px;
  font-weight: bold;
  color: #55504f;
}

.mainDiv h2 {
  font-size: 40px;
  font-weight: bold;
  color: #fffff2;
}

.mainDiv h3 {
  font-size: 16px;
  font-weight: bold;
  color: #55504f;
}

.mainDiv button {
  width: 340px;
  height: 40px;
  border-radius: 7px;
  background-color: #fffff2;
  margin: 100px 0;
}

@media only screen and (max-width: 768px) {
  .mainDiv h1 {
    font-size: 100px;
    font-weight: bold;
    color: #55504f;
  }

  .mainDiv h2 {
    font-size: 20px;
    font-weight: bold;
    color: #fffff2;
  }

  .mainDiv h3 {
    font-size: 12px;
    font-weight: bold;
    color: #55504f;
  }

  .mainDiv button {
    width: 150px;
    height: 30px;
    border-radius: 7px;
    background-color: #fffff2;
    margin: 100px 0;
  }
}
